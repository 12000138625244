import { Factory, OrderInputFactoryInterface } from '~/abstracts/factory'
import { OrderFormType } from '~/features/order/factory/order-form-factory'
import { GQLOrderInput, GQLOrderPaymentCode } from '~/types/gql'

export class OrderInputFactory extends Factory<OrderFormType, GQLOrderInput>
  implements OrderInputFactoryInterface {
  create(input: OrderFormType): GQLOrderInput {
    return {
      id: input.id,
      shipping: input.shipping,
      // Редактирование товаров в заказе не реализовано
      // и изменение способа оплаты
      items: [],
      payment: GQLOrderPaymentCode.Cash,
      customer: {
        name: input.customer,
        email: input.customerEmail,
        phone: input.customerPhone,
      },
      address: {
        ...input.deliveryData,
      },
      comment: input.userComment,
    }
  }
}
